/* global System */
// register component(s) with react habitat

import withLazyUnveil from '../../scripts/higher-order-components/withLazyUnveil'

export default {
  NewReactVC: withLazyUnveil({
    loadComponent: () =>
      System.import(
        /* webpackChunkName: "NewReactVC" */
        './Component'
      ),
    height: 400,
    mobileHeight: 400,
  }),
}
