/* global System */
// register component(s) with react habitat

import withLazyUnveil from '../../scripts/higher-order-components/withLazyUnveil'

export default {
  DhsvJobportal: withLazyUnveil({
    loadComponent: () =>
      System.import(
        /* webpackChunkName: "DhsvJobportal" */
        './consent'
      ),
    height: 2500,
    mobileHeight: 2900,
  }),
}
