/* global gform tinyMCE */
import { mkJobAdTemplateNav } from './jobAdTemplates'

// this implements a mechanism for loading templates into the rich text field of the 'Inserat erstellen' gravity form
export default () => {
  gform.addAction('gform_post_conditional_logic_field_action', function (
    formId,
    action,
    targetId
  ) {
    //form #3 is 'Inserat erstellen' form, field #24 is the rich text field for the body text
    if (targetId === '#field_3_24') {
      const jobAdTemplatesList = document.querySelector(
        '#gform_3 .job-ad-templates'
      )
      if (action === 'hide' && jobAdTemplatesList) {
        // to be compatible with ie11, call parentNode.removeChild() instead of remove()
        jobAdTemplatesList.parentNode.removeChild(jobAdTemplatesList)
      }
      if (action === 'show' && !jobAdTemplatesList && tinyMCE) {
        const jobAdBodyField = document.querySelector('#gform_3 #field_3_24')
        const navigation = mkJobAdTemplateNav(tinyMCE.get('input_3_24'))
        jobAdBodyField.insertAdjacentElement('afterend', navigation)
      }
    }
  })
}
