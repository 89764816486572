import React from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-slick'
import Overlay from './Overlay'
import './slider.scss'

class HomeSlider extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const {
      slides,
      title,
      finderLink,
      filterOptions,
      strings,
      hide_overlay,
    } = this.props
    const settings = {
      dots: true,
    }
    return (
      <div className={`dhsv_home_slider ${hide_overlay ? 'hideoverlay' : ''}`}>
        <Slider {...settings}>
          {slides.map((slide, i) => (
            <div className="item" key={i}>
              <div className="img-wrapper">
                <div
                  className="item__img"
                  dangerouslySetInnerHTML={{ __html: slide.image }}
                />
                {slide.caption ? (
                  <div className="item__caption">
                    <div className="container">
                      <div
                        className="item__caption-img"
                        dangerouslySetInnerHTML={{ __html: slide.caption }}
                      />
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
          ))}
        </Slider>
        {!hide_overlay && (
          <Overlay
            title={title}
            finderLink={finderLink}
            filterOptions={filterOptions}
            strings={strings}
          />
        )}
      </div>
    )
  }
}

HomeSlider.propTypes = {
  slides: PropTypes.array,
  title: PropTypes.string,
  finderLink: PropTypes.string,
  filterOptions: PropTypes.object,
  strings: PropTypes.object,
  hide_overlay: PropTypes.bool,
}

export default HomeSlider
