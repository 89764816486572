/* global System */
// register component(s) with react habitat

import withLazyUnveil from '../../scripts/higher-order-components/withLazyUnveil'

export default {
  TeaserSlider: withLazyUnveil({
    loadComponent: () =>
      System.import(
        /* webpackChunkName: "TeaserSlider" */
        './Component'
      ),
    height: 670,
    mobileHeight: 590,
  }),
}
