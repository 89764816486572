const sani = `
<p>Als innovatives und zukunftsorientiertes Sanitätshaus sind wir Mitglied der Sanitätshaus Aktuell AG, einem modernen,
 national und international tätigen Dienstleistungsunternehmen der Gesundheitsbranche. Der Smiley ist unser
 freundliches Markenzeichen und Wegweiser für Qualität, kompetente Beratung in einer angenehmen Atmosphäre und einem
 leistungsstarken Service.</p>
<p style="font-weight:bold">Für unseren Standort suchen wir zur Verstärkung unseres Teams zum nächstmöglichen
 Zeitpunkt eine(n)</p>
<p class="h2">Sanitätshausfachverkäufer/-in (m/w/d)</p>
<p style="font-weight:bold">Ihr Aufgabengebiet:</p>
<ul>
  <li>Beratung, Verkauf und Anpassung von Hilfsmitteln aus den Bereichen: Bandagen, Kompression, Inkontinenz,
 Brustprothetik, orthopädische Einlagen sowie von frei verkäuflichen Artikeln</li>
  <li>organisatorische Tätigkeiten wie das Erfassen von Bestellungen, das Führen der Kasse und ggf. die Abrechnung
 von Rezepten</li>
  <li>Kommunikation mit Kunden und Kostenträgern</li>
</ul>
<p style="font-weight:bold">Ihr Profil:</p>
<ul>
  <li>Kauffrau/-mann im Einzelhandel oder Verkäufer/-in, mit oder ohne Berufserfahrung</li>
  <li>kommunikatives und kundenorientiertes Denken und Handeln</li>
  <li>sicheres, freundliches und kompetentes Auftreten</li>
  <li>Organisationstalent und hohes Engagement</li>
</ul>
<p>Wir bieten Ihnen eine verantwortungsvolle Position in einem spannenden und dynamischen Umfeld, sowie die Mitarbeit
 in einem motivierten Team. Es erwarten Sie interessante und anspruchsvolle Aufgaben mit der Möglichkeit, sich
 langfristig bei uns weiterzuentwickeln, eine leistungsgerechte Bezahlung, eine gründliche Einarbeitung sowie die
 Unterstützung durch ein engagiertes Management und Mitarbeiterteam. Senden Sie bitte Ihre Bewerbungsunterlagen mit
 möglichem Eintrittsdatum und Gehaltsvorstellung an unsere Kontaktdaten (siehe Seite).</p>
`

const ortho = `
<p>Als innovatives und zukunftsorientiertes Sanitätshaus sind wir Mitglied der Sanitätshaus Aktuell AG, einem modernen,
 national und international tätigen Dienstleistungsunternehmen der Gesundheitsbranche. Der Smiley ist unser
 freundliches Markenzeichen und Wegweiser für Qualität, kompetente Beratung in einer angenehmen Atmosphäre und einem
 leistungsstarken Service.</p>
<p style="font-weight:bold">Für unseren Standort suchen wir zur Verstärkung unseres Teams zum nächstmöglichen
 Zeitpunkt eine(n) </p>
<p class="h2">Orthopädietechnik-Mechaniker/in (m/w/d)</p>
<p style="font-weight:bold">Ihr Aufgabengebiet:</p>
<ul>
  <li>qualifizierte Versorgung von Patienten in Orthetik, Prothetik und Kleinorthopädie</li>
  <li>Unterstützung unseres Orthopädiemeisters im operativen Tagesgeschäft</li>
  <li>Betreuung von niedergelassenen Ärzten im Außendienst</li>
</ul>
<p style="font-weight:bold">Ihr Profil:</p>
<ul>
  <li>abgeschlossene Ausbildung zum Orthopädietechnik-Mechaniker/in mit oder ohne Berufserfahrung</li>
  <li>sicheres, freundliches und kompetentes Auftreten</li>
  <li>Organisationstalent und hohes Engagement</li>
  <li>PKW-Führerschein</li>
</ul>
<p>Wir bieten Ihnen eine verantwortungsvolle Position in einem spannenden und dynamischen Umfeld, sowie die Mitarbeit
 in einem motivierten Team. Es erwarten Sie interessante und anspruchsvolle Aufgaben mit der Möglichkeit, sich
 langfristig bei uns weiterzuentwickeln, eine leistungsgerechte Bezahlung, eine gründliche Einarbeitung sowie die
 Unterstützung durch ein engagiertes Management und Mitarbeiterteam. Senden Sie bitte Ihre Bewerbungsunterlagen mit
 möglichem Eintrittsdatum und Gehaltsvorstellung an unsere Kontaktdaten (siehe Seite).</p>
`

const reha = `
<p>Als innovatives und zukunftsorientiertes Sanitätshaus sind wir Mitglied der Sanitätshaus Aktuell AG, einem modernen,
 national und international tätigen Dienstleistungsunternehmen der Gesundheitsbranche. Der Smiley ist unser
 freundliches Markenzeichen und Wegweiser für Qualität, kompetente Beratung in einer angenehmen Atmosphäre und einem
 leistungsstarken Service.</p>
<p style="font-weight:bold">Für unseren Standort suchen wir zur Verstärkung unseres Teams zum nächstmöglichen
 Zeitpunkt eine(n)</p>
<p class="h2">Rehatechniker-/in (m/w/d)</p>
<p style="font-weight:bold">Ihr Aufgabengebiet:</p>
<ul>
  <li>Anpassung von Reha-Hilfsmitteln an die Bedürfnisse unserer Kunden, Beratung und Betreuung</li>
  <li>Reparaturen und Wartung der Geräte</li>
  <li>eventuell Außendiensttätigkeit</li>
</ul>
<p style="font-weight:bold">Ihr Profil:</p>
<ul>
  <li>abgeschlossene technische oder handwerkliche Ausbildung mit oder ohne Berufserfahrung</li>
  <li>Empathie - Erfahrung im Umgang mit Hilfsmitteln erwünscht</li>
  <li>kommunikatives und kundenorientiertes Denken und Handeln</li>
  <li>sicheres, freundliches und kompetentes Auftreten</li>
  <li>Organisationstalent und hohes Engagement</li>
  <li>PKW-Führerschein</li>
</ul>
<p>Wir bieten Ihnen eine verantwortungsvolle Position in einem spannenden und dynamischen Umfeld, sowie die Mitarbeit
 in einem motivierten Team. Es erwarten Sie interessante und anspruchsvolle Aufgaben mit der Möglichkeit, sich
 langfristig bei uns weiterzuentwickeln, eine leistungsgerechte Bezahlung, eine gründliche Einarbeitung sowie die
 Unterstützung durch ein engagiertes Management und Mitarbeiterteam. Senden Sie bitte Ihre Bewerbungsunterlagen mit
 möglichem Eintrittsdatum und Gehaltsvorstellung an unsere Kontaktdaten (siehe Seite).</p>
`

const stoma = `
<p>Als innovatives und zukunftsorientiertes Sanitätshaus sind wir Mitglied der Sanitätshaus Aktuell AG, einem modernen,
 national und international tätigen Dienstleistungsunternehmen der Gesundheitsbranche. Der Smiley ist unser
 freundliches Markenzeichen und Wegweiser für Qualität, kompetente Beratung in einer angenehmen Atmosphäre und einem
 leistungsstarken Service.</p>
<p style="font-weight:bold">Für unseren Standort suchen wir zur Verstärkung unseres Teams zum nächstmöglichen Zeitpunkt
 eine(n)</p>
<p class="h2">Stomatherapeut/in (m/w/d)</p>
<p style="font-weight:bold">Ihr Aufgabengebiet:</p>
<ul>
  <li>Beratung von Patienten in den Fachbereichen Stoma, Wunde und Inkontinenz</li>
  <li>Auslieferung von Pflegehilfsmitteln und Anleitung in deren Handhabung</li>
</ul>
<p style="font-weight:bold">Ihr Profil:</p>
<ul>
  <li>exam. Gesundheits- und Krankenpfleger/in oder Altenpfleger/in</li>
  <li>abgeschlossene Weiterbildung zum Wundexperten (ICW) und/oder Stomatherapeut/in (DEVT)</li>
  <li>kommunikatives und kundenorientiertes Denken und Handeln</li>
  <li>sicheres, freundliches und kompetentes Auftreten</li>
  <li>Organisationstalent und hohes Engagement</li>
  <li>PKW-Führerschein</li>
</ul>
<p>Wir bieten Ihnen eine verantwortungsvolle Position in einem spannenden und dynamischen Umfeld, sowie die Mitarbeit
 in einem motivierten Team. Es erwarten Sie interessante und anspruchsvolle Aufgaben mit der Möglichkeit, sich
 langfristig bei uns weiterzuentwickeln, eine leistungsgerechte Bezahlung, eine gründliche Einarbeitung sowie die
 Unterstützung durch ein engagiertes Management und Mitarbeiterteam. Senden Sie bitte Ihre Bewerbungsunterlagen mit
 möglichem Eintrittsdatum und Gehaltsvorstellung an unsere Kontaktdaten (siehe Seite).</p>
`

const jobAdTemplates = {
  sani,
  ortho,
  reha,
  stoma,
}

const navigationHtml = `
<span class="h5">Vorlage laden:</span>
<ul>
  <li><a href="#" class="sani">Sanitätshausfachverkäufer/in</a></li>
  <li><a href="#" class="ortho">Orthopädietechniker/in</a></li>
  <li><a href="#" class="reha">Rehatechniker/in</a></li>
  <li><a href="#" class="stoma">Stomatherapeut/in</a></li>
</ul>`

const confirmationText =
  'Wollen Sie wirklich eine neue Vorlage laden? Ihr vorhandener Text wird ersetzt.'

let lastTemplate = null

export const mkJobAdTemplateNav = tinymceInstance => {
  const list = document.createElement('div')
  list.onclick = e => {
    e.preventDefault()
    const {
      target: { className, tagName },
    } = e
    if (tagName === 'A' && className) {
      const template = jobAdTemplates[className]
      if (tinymceInstance && template) {
        const editorContent = tinymceInstance.getContent()
        if (
          !editorContent ||
          lastTemplate === editorContent ||
          window.confirm(confirmationText)
        ) {
          tinymceInstance.setContent(template)
          lastTemplate = tinymceInstance.getContent()
        }
      }
    }
  }
  list.className = 'job-ad-templates'
  list.innerHTML = navigationHtml
  return list
}
