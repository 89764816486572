/* global System */
// register component(s) with react habitat

import withLazyUnveil from '../../scripts/higher-order-components/withLazyUnveil'

export default {
  PostsCarousel: withLazyUnveil({
    loadComponent: () =>
      System.import(
        /* webpackChunkName: "PostsCarousel" */
        './PostsCarousel'
      ),
    height: 450,
    mobileHeight: 200,
  }),
}
