/* Don't forget to add string 'news' to function combineReducers in './posts.js' */
import { WP_API_BASE } from '../../shared/constants'

const initialState = {
  isLoading: false,
  isLoaded: false,
  error: null,
  posts: [],
  activeFilters: {
    category: [],
  },
}

const LOAD_POSTS_DATA = 'LOAD_POSTS_DATA'
const LOAD_POSTS_DATA_SUCCESS = 'LOAD_POSTS_DATA_SUCCESS'
const LOAD_POSTS_DATA_FAILURE = 'LOAD_POSTS_DATA_FAILURE'
const SET_POSTS_ACTIVE_FILTERS = 'SET_POSTS_ACTIVE_FILTERS'

export default (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case LOAD_POSTS_DATA:
      return {
        ...state,
        isLoading: true,
      }

    case LOAD_POSTS_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        posts: payload,
      }

    case LOAD_POSTS_DATA_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
      }

    case SET_POSTS_ACTIVE_FILTERS:
      return {
        ...state,
        activeFilters: { ...payload },
      }

    default:
      return state
  }
}

export const loadPosts = (
  per_page,
  offset,
  magazine_term = undefined,
  filters = undefined
) => dispatch => {
  dispatch({ type: LOAD_POSTS_DATA })

  // Rest API Params
  const params = {
    per_page: per_page,
    offset: offset,
    magazine_term: magazine_term,
  }

  let apiBaseUrl = WP_API_BASE

  if (filters !== undefined) {
    Object.keys(filters).map(key => {
      if (filters[key].length) {
        params[key] = filters[key].join(',')
      }
    })
  }

  let url_params = '/'
  if (params) {
    let i = 0
    for (let key in params) {
      Object.prototype.hasOwnProperty.call(params, key)
        ? (url_params += (i === 0 ? '?' : '&') + key + '=' + params[key])
        : false
      i++
    }
  }

  return fetch(`${apiBaseUrl}posts${url_params}`, {
    method: 'GET',
  })
    .then(response => {
      if (response.ok && response.status === 200) {
        return response.json()
      }
      throw new Error(response.statusText)
    })
    .then(response => {
      dispatch({
        payload: response,
        type: LOAD_POSTS_DATA_SUCCESS,
      })

      if (filters !== undefined) {
        dispatch({
          payload: filters,
          type: SET_POSTS_ACTIVE_FILTERS,
        })
      }

      return response
    })
    .catch(error => {
      if (process.env.NODE_ENV === 'development') {
        console.error(error) //eslint-disable-line
      }
      dispatch({
        error: error,
        type: LOAD_POSTS_DATA_FAILURE,
      })
    })
}
