/* global System */
// register component(s) with react habitat

import withLazyUnveil from '../../scripts/higher-order-components/withLazyUnveil'

export default {
  Events: withLazyUnveil({
    loadComponent: () =>
      System.import(
        /* webpackChunkName: "Events" */
        './data'
      ),
    height: 800,
    mobileHeight: 1200,
  }),
}
