/* global System */
// register component(s) with react habitat

import withLazyUnveil from '../../higher-order-components/withLazyUnveil'

export default {
  MobileMenu: withLazyUnveil({
    loadComponent: () =>
      System.import(
        /* webpackChunkName: "MobileMenu" */
        './index'
      ),
    height: 200,
    mobileHeight: 200,
  }),
}
