import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Loader } from '@googlemaps/js-api-loader'
import globalData from '../shared/globalData'

export const googleMapsApiLoadingType = PropTypes.oneOf(['loading', 'loaded'])

export const googleMapsApiOptions = {
  apiKey: globalData.googleMapsApiKey,
  libraries: ['places'],
}

const withGoogleMapsApi = Component => {
  const WithGoogleMapsApi = props => {
    const [googleMapsApiLoading, setGoogleMapsApiLoading] = useState(null)

    useEffect(() => {
      if (!googleMapsApiLoading) {
        const loader = new Loader(googleMapsApiOptions)
        loader.load().then(() => setGoogleMapsApiLoading('loaded'))
        setGoogleMapsApiLoading('loading')
      }
    })

    Component.propTypes = {
      ...(Component.propTypes || {}),
      googlePlacesApiLoading: googleMapsApiLoadingType,
    }

    return <Component {...props} googleMapsApiLoading={googleMapsApiLoading} />
  }

  return WithGoogleMapsApi
}

export default withGoogleMapsApi
