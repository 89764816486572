/* global System */
// register component(s) with react habitat

import withLazyUnveil from '../../scripts/higher-order-components/withLazyUnveil'

export default {
  MultiSlider: withLazyUnveil({
    loadComponent: () =>
      System.import(
        /* webpackChunkName: "MultiSlider" */
        './MultiSlider'
      ),
    height: 450,
    mobileHeight: 200,
  }),
}
