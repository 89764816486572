import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import './style.scss'

function Select({
  placeholder,
  value = [],
  fieldName,
  strings,
  onSubmit,
  options,
  selected = [],
}) {
  const [val, setVal] = useState(value)
  const [open, setOpen] = useState(false)
  const selectedString = options
    .filter(o => val.includes(o.value))
    .map(o => o.label)
    .join(', ')

  useEffect(() => {
    setVal(selected)
  }, [selected])

  const addVal = newVal => {
    setVal(old => {
      return [...old, newVal]
    })
  }

  const removeVals = vals => {
    setVal(val.filter(e => !vals.includes(e)))
  }

  const setOpenState = () => {
    if (open) {
      setOpen(false)
      onSubmit(fieldName, val)
    } else {
      setOpen(true)
    }
  }

  return (
    <div
      className={`dhsv_select field_${fieldName} ${open ? 'open' : ''}`}
      id={`field_${fieldName}`}
    >
      <span className="placeholder" onClick={setOpenState}>
        {val.length ? selectedString : placeholder}
      </span>
      <div className="select-overlay">
        <div className="meta">
          <div className="title" onClick={setOpenState}>
            <span>{placeholder}</span>
            <i className="icon ion-ios-close-empty" />
          </div>
          <div className="addition">
            <span className="selected">
              {val.length} {strings.selected}
            </span>
            <span className="reset" onClick={() => removeVals(val)}>
              {strings.reset}
            </span>
          </div>
        </div>
        {options.length ? (
          <div className="options">
            {options.map((option, key) => {
              return (
                <div
                  className={
                    'option' + (val.includes(option.value) ? ' selected' : '')
                  }
                  onClick={() =>
                    val.includes(option.value)
                      ? removeVals([option.value])
                      : addVal(option.value)
                  }
                  key={key}
                >
                  <span>{option.label}</span>
                  <i className="icon ion-ios-close-empty" />
                </div>
              )
            })}
          </div>
        ) : null}
        <div className="footer">
          <button onClick={setOpenState}>{strings.takeOptions}</button>
        </div>
      </div>
      <i className="aftericon icon ion-ios-arrow-down" onClick={setOpenState} />
    </div>
  )
}

Select.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string,
  fieldName: PropTypes.string,
  onSubmit: PropTypes.func,
  strings: PropTypes.object,
  options: PropTypes.array,
  selected: PropTypes.array,
}

export default Select
