import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import PlacesAutocomplete from 'react-places-autocomplete'

import withGoogleMapsApi, {
  googleMapsApiLoadingType,
} from 'scripts/higher-order-components/withGoogleMapsApi'

import './style.scss'
import google_logo from 'images/powered_by_google.png'

function SearchField({
  placeholder,
  value,
  onSubmit,
  fieldName,
  strings,
  error,
  googleMapsApiLoading,
}) {
  const [val, setVal] = useState(value)
  const [address, setAddress] = useState('')
  const inputWrapperRef = useRef(null)

  useEffect(() => {
    document.addEventListener('mousedown', handleInputBlur)
    return () => {
      document.removeEventListener('mousedown', handleInputBlur)
    }
  }, [address])

  useEffect(() => {
    setVal(value)
    setAddress(value)
  }, [value])

  const handleKeypress = e => {
    if (e.key === 'Enter') {
      onSubmit(fieldName, val)
    }
  }

  const onError = (status, clearSuggestions) => {
    console.log('Google Maps API returned error with status: ', status) //eslint-disable-line
    clearSuggestions()
  }

  const handleInputFocus = e => {
    e.target.select()
  }

  const handleInputBlur = e => {
    if (
      inputWrapperRef.current &&
      !inputWrapperRef.current.contains(e.target)
    ) {
      setVal(address)
    }
  }

  const handleSearchClick = () => {
    onSubmit(fieldName, val)
  }

  const handleBlurSearch = () => {
    console.log('handleBlurSearch handleBlurSearch handleBlurSearch')
  }

  const searchOptions = {
    componentRestrictions: { country: ['de'] },
  }

  return (
    <div className="search-wrapper">
      {googleMapsApiLoading === 'loaded' && (
        <PlacesAutocomplete
          value={val}
          onChange={v => {
            setVal(v)
          }}
          onSelect={address => {
            onSubmit(fieldName, address)
          }}
          onError={onError}
          searchOptions={searchOptions}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <div
              className={`dhsv_search_field field_${fieldName} ${
                error ? 'error' : ''
              } ${suggestions.length ? 'open' : ''}`}
              id={`field_${fieldName}`}
              ref={inputWrapperRef}
            >
              <input
                type="text"
                placeholder={placeholder}
                onKeyPress={handleKeypress}
                {...getInputProps({
                  placeholder: placeholder,
                  className: 'location-search-input',
                  onFocus: handleInputFocus,
                  onBlur: handleBlurSearch,
                })}
              />
              <i
                className="aftericon icon ion-ios-search"
                onClick={handleSearchClick}
              />
              <div className={'autocomplete-dropdown-container'}>
                <div className="title">{placeholder}</div>
                {loading && <div className="loading">Loading...</div>}
                {suggestions.map((suggestion, key) => {
                  const className = suggestion.active
                    ? 'suggestion-item--active'
                    : 'suggestion-item'
                  // inline style for demonstration purpose
                  const style = suggestion.active
                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                    : { backgroundColor: '#ffffff', cursor: 'pointer' }
                  return (
                    <div
                      key={key}
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                      })}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  )
                })}
                {suggestions && suggestions.length > 0 && (
                  <div className="google_logo">
                    <img src={google_logo} />
                  </div>
                )}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
      )}
      {error ? (
        <span className="error-message">{strings.error[error]}</span>
      ) : null}
    </div>
  )
}

SearchField.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string,
  fieldName: PropTypes.string,
  error: PropTypes.string,
  onSubmit: PropTypes.func,
  strings: PropTypes.object,
  googleMapsApiLoading: googleMapsApiLoadingType,
}

export default withGoogleMapsApi(SearchField)
