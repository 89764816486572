/* global System */
// register component(s) with react habitat

import withLazyUnveil from '../../higher-order-components/withLazyUnveil'

export default {
  SaniFinder: withLazyUnveil({
    loadComponent: () =>
      System.import(
        /* webpackChunkName: "SaniFinder" */
        './index'
      ),
    height: 800,
    mobileHeight: 1200,
  }),
}
