import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import withCookieConsent from 'scripts/higher-order-components/withCookieConsent'
import SearchField from 'scripts/components/SaniFinder/fields/SearchField'
import Select from 'scripts/components/SaniFinder/fields/Select'

const storageFilter = JSON.parse(sessionStorage.getItem('sanifinder_filter'))

const INITIAL_FILTER = {
  search: '',
  product: [],
}

const Overlay = ({ title, finderLink, filterOptions, strings }) => {
  const [filter, setFilter] = useState(storageFilter || INITIAL_FILTER)

  useEffect(() => {
    if (storageFilter && storageFilter.product) {
      updateFilter('product', storageFilter.product)
    }

    if (storageFilter && storageFilter.search) {
      updateFilter('search', storageFilter.search)
    }
  }, [])

  useEffect(() => {
    //sessionStorage.setItem('sanifinder_filter', JSON.stringify(filter))
  }, [filter])

  const updateFilter = (field, val) => {
    if (field) {
      setFilter(old => {
        return {
          ...old,
          [field]: val,
        }
      })

      if (field === 'search') {
        setNewLocationFromSearch(val)
      }
    }
  }

  const setNewLocationFromSearch = val => {
    fetch(
      `${window.location.origin}/wp-json/dhsv/v1/geocode?address=${encodeURI(
        val
      )}`
    )
      .then(resp => resp.json())
      .then(data => {
        if (data.results.length && data.results[0].formatted_address) {
          setFilter(old => {
            return {
              ...old,
              search: data.results[0].formatted_address,
            }
          })
        }
      })
  }

  const goToFinder = () => {
    let urlArray = []
    Object.keys(filter).map(key => {
      urlArray =
        key === 'product'
          ? [
              ...urlArray,
              `${key}=${encodeURIComponent(filter[key].map(i => i).join(';'))}`,
            ]
          : [...urlArray, `${key}=${encodeURIComponent(filter[key])}`]
    })

    const urlString = urlArray.join('&')
    window.open(finderLink + '?' + urlString)
  }

  return (
    <>
      <div>
        <p className="h3 text-orange">{title}</p>
      </div>
      <div>
        <SearchField
          placeholder={strings.plz_ort}
          value={filter.search}
          fieldName="search"
          onSubmit={updateFilter}
          strings={strings}
        />
        <Select
          placeholder={strings.product}
          strings={strings}
          selected={filter.product}
          fieldName="product"
          onSubmit={updateFilter}
          options={filterOptions.product}
        />
        <button className="btn btn-primary" onClick={goToFinder}>
          {strings.show_all}
        </button>
      </div>
    </>
  )
}

Overlay.propTypes = {
  title: PropTypes.string,
  finderLink: PropTypes.string,
  filterOptions: PropTypes.object,
  strings: PropTypes.object,
}

const WithConsent = withCookieConsent({
  Component: Overlay,
  cookieId: 'googlemaps',
  consentRequestMessage:
    'Um den Sanitätshausfinder zu nutzen, müssen sie das Cookie "Google Maps" in der Gruppe "External Media" akzeptieren!',
})

const WrappedOverlay = props => {
  return (
    <div className="dhsv_home_slider__overlay">
      <div className="container">
        <div className="row">
          <div className="col-12 dhsv_home_slider__overlay_wrapper">
            <WithConsent {...props} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default WrappedOverlay
