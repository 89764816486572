import React, { useEffect, useState, useRef, Suspense, lazy } from 'react'
import { connect } from 'react-redux'
import LoadingIndicator from '../components/LoadingIndicator'
import PropTypes from 'prop-types'
import { sIsMobile } from '../redux/modules/browser'

const mapStateToProps = s => ({ isMobile: sIsMobile(s) })

const withLazyUnveil = ({
  loadComponent,
  height = 500,
  mobileHeight = 700,
  showMobile = true,
  showDesktop = true,
  LoadingIndicatorComponent = LoadingIndicator,
}) => {
  const LazyComponent = lazy(loadComponent)

  const LazyUnveil = props => {
    const ref = useRef(null)
    const [startedLoading, updateLoading] = useState(false)
    const [listenerAttached, updateListenerAttached] = useState(false)
    const { isMobile } = props

    if ((isMobile && !showMobile) || (!isMobile && !showDesktop)) {
      return null
    }

    const handleUnveil = ({ target }) => {
      if (target !== ref.current || startedLoading) {
        return
      }

      updateLoading(true)
    }

    useEffect(() => {
      document.addEventListener('lazybeforeunveil', handleUnveil)
      updateListenerAttached(true)

      return () =>
        document.removeEventListener('lazybeforeunveil', handleUnveil)
    })

    const finalHeight = isMobile ? mobileHeight : height

    const wrapperStyle = {
      height: finalHeight + 'px',
      paddingTop: finalHeight / 3 + 'px',
    }

    const preload = !isMobile ? ' lazypreload' : ''

    return startedLoading ? (
      <Suspense
        fallback={
          <div
            className="with-lazy-unveil lazyloaded suspense-loading"
            style={wrapperStyle}
          >
            <LoadingIndicatorComponent />
          </div>
        }
      >
        <div className="with-lazy-unveil lazyloaded suspense-loaded">
          <LazyComponent {...props} />
        </div>
      </Suspense>
    ) : (
      <div
        ref={ref}
        className={`with-lazy-unveil ${
          listenerAttached ? 'lazyload' + preload : ''
        }`}
        style={wrapperStyle}
      >
        <LoadingIndicatorComponent />
      </div>
    )
  }

  LazyUnveil.propTypes = {
    isMobile: PropTypes.bool,
  }

  return connect(mapStateToProps)(LazyUnveil)
}

export const withLazyUnveilPromiseThunk = args => () =>
  Promise.resolve(withLazyUnveil(args))

export default withLazyUnveil
