import { stripTags } from '../util/string'
import { mkJobAdTemplateNav } from './jobAdTemplates'

if (window.acf) {
  const { acf } = window

  // strip html tags from the select2 title attribute of the "place" acf field
  // this is done because we render the "place"-logo in the option label, which select2 also uses as title
  acf.addAction('select2_init', ($select, args, settings, field) => {
    const {
      $el,
      data: { name },
    } = field
    if (name === 'place') {
      const selectionRendered = $el[0].querySelector(
        '.select2-selection__rendered'
      )
      selectionRendered.setAttribute(
        'title',
        stripTags(selectionRendered.title)
      )
    }
  })

  // implements mechanism for loading templates into the "post_content" tiny mce instance
  acf.addAction('wysiwyg_tinymce_init', (ed, _, __, { $el }) => {
    const enableJobAdTemplates = acf.get('enableJobAdTemplates')
    if (enableJobAdTemplates && $el && $el[0]) {
      const navigation = mkJobAdTemplateNav(ed)
      $el[0].insertAdjacentElement('afterend', navigation)
    }
  })
}
