import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const checkCookieConsent = cookieId => {
  if (!window.BorlabsCookie) {
    return false
  }
  return window.BorlabsCookie.checkCookieConsent(cookieId)
}

const openCookiePreference = () => {
  if (window.BorlabsCookie) {
    window.BorlabsCookie.openCookiePreference()
  }
}

const ConsentRequest = ({ className, consentRequestMessage }) => {
  return (
    <div className={classnames('dhsv-consent-request', className)}>
      <span className="consent-message">
        {consentRequestMessage}
        <br />
        <a
          href="#"
          onClick={e => {
            e.preventDefault()
            openCookiePreference()
          }}
        >
          zu den Cookie-Präferenzen
        </a>
      </span>
    </div>
  )
}

ConsentRequest.propTypes = {
  className: PropTypes.string,
  consentRequestMessage: PropTypes.node,
}

const withCookieConsent = ({
  Component,
  className,
  consentRequestMessage,
  cookieId,
}) => {
  const WithCookieConsent = props => {
    const [consent, setConsent] = useState(false)
    const updateConsentFromBorlabs = () =>
      setConsent(checkCookieConsent(cookieId))

    useEffect(() => {
      updateConsentFromBorlabs()
      const interval = setInterval(updateConsentFromBorlabs, 1000)
      return () => clearInterval(interval)
    })

    if (!consent) {
      return (
        <ConsentRequest
          className={className}
          consentRequestMessage={consentRequestMessage}
        />
      )
    }

    return <Component {...props} />
  }

  return WithCookieConsent
}

export default withCookieConsent
