import $ from 'jquery'
import 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.js'

//Skip lazy loading for images inside scroll element

$(document).on('ready', function () {
  $('.anchor__item')
    .find('img')
    .each(function () {
      let width = $(this).attr('width')
      $(this).css('width', width)
    })
})

function dhsv_vc_anchor_links(callbacks, update = false) {
  $('.anchor_links:not(.anchor_links__mobiles)').each(function () {
    let $this = $(this)
    let $list = $this.find('.list_element').clone()
    $this.find('.list_element').remove()
    $list.appendTo($this.find('.anchor_links__list'))

    /* Add padding for the last item */
    let containerHeight = $(this).find('.anchor_links__content').outerHeight()
    let lastElement = $this.find('.anchor__item:last-of-type')
    lastElement.css('padding-bottom', '')
    if (
      containerHeight !== undefined &&
      lastElement.outerHeight() < containerHeight
    ) {
      lastElement.css(
        'padding-bottom',
        containerHeight - lastElement.outerHeight()
      )
    } else {
      lastElement.css('padding-bottom', '150px')
    }

    $this
      .find('.anchor_links__list .list_element:first-of-type')
      .addClass('active')

    if (update) {
      $('.anchor_links__content').mCustomScrollbar('update')
    } else {
      $this.find('.anchor_links__content').mCustomScrollbar({
        theme: 'dark',
        scrollbarPosition: 'outside',
        mouseWheelPixels: 150,
        mouseWheel: {
          enable: true,
        },
        autoDraggerLength: false,
        contentTouchScroll: true,
        advanced: { updateOnImageLoad: true },
        callbacks: {
          whileScrolling: function () {
            let sectionScroll = Math.abs(this.mcs.top)
            $this.find('.anchor__item').each(function () {
              let sectionPosition = $(this).position().top
              if (sectionScroll >= sectionPosition) {
                $this.find('.list_element').removeClass('active')
                let sectionId = $(this).data('content')
                $this
                  .find('.list_element[data-item="' + sectionId + '"]')
                  .addClass('active')
              }
            })
          },
          ...callbacks,
        },
      })
      $(window).trigger('resize')
    }
  })
}

function show_hide_text() {
  $('.anchor_links:not(.anchor_links__mobiles)').each(function () {
    let $this = $(this),
      offset = $(this).offset(),
      $window = $(window),
      $windowHeight = $window.outerHeight()

    if ($(window).scrollTop() > offset.top - $windowHeight / 2) {
      setTimeout(function () {
        $this.find('.mCSB_dragger').addClass('no_text')
      }, 3000)
    } else {
      $this.find('.mCSB_dragger').removeClass('no_text')
    }
  })
}

$('.vc_tta-tab').on('click', 'a', function () {
  $('.anchor_links:not(.anchor_links__mobiles)').each(function () {
    let $this = $(this)
    $this.find('.mCSB_dragger').removeClass('no_text')
    setTimeout(function () {
      $this.find('.mCSB_dragger').addClass('no_text')
    }, 3000)

    $('.anchor_links')
      .find('.anchor_links__list .list_element')
      .removeClass('active')
    $('.anchor_links')
      .find('.anchor_links__list .list_element:first-of-type')
      .addClass('active')
  })

  //reinit mCustomScrollbar update padding-bottom after VC_tabs change
  setTimeout(function () {
    dhsv_vc_anchor_links('', true)
  }, 1000)
})

$(window).on('load', function () {
  const scrollToHash = () => {
    const hash = window.location.hash.replace('#', '')
    // the reason for the prefix is to prevent the browser from trying to scroll to the id
    const scrollTo = `anchor_${hash}`
    if (scrollTo.length) {
      // setTimeout is necessary because without it mCustomScrollbar() does not reliably scroll to the target
      setTimeout(() => {
        const contentEl = $('.anchor_links__content')
        contentEl.mCustomScrollbar(
          'scrollTo',
          $(`.anchor_links__content .anchor__item[data-content=${scrollTo}]`)
        )
        contentEl.mCustomScrollbar('update')
      }, 100)
    }
  }

  if ($(window).width() > 991) {
    dhsv_vc_anchor_links({ onInit: scrollToHash })
    show_hide_text()
  }
})

$(window).scroll(function () {
  if ($(window).width() > 991) {
    show_hide_text()
  }
})

$(document).on('click', '.list_element', function () {
  let target = $(this).data('item')
  let $el = $('.anchor_links__content').find(
    '.anchor__item[data-content=' + target + ']'
  )
  let $parent = $(this).closest('.anchor_links')

  $parent.find('.anchor_links__content').mCustomScrollbar('scrollTo', $el, {
    // disable "onStart" callback
    // this seems to fix mCustomScrollbar() not scolling far enough on some screen resolutions
    // or when the browser window is zoomed out
    onStart: false,
  })
})

/* Mobile tabs */

$(document).on('click', '.tab-item-title', function () {
  if ($(this).hasClass('active')) {
    $(this).removeClass('active').siblings('.tab-item-content').slideUp()
  } else {
    let $parent = $(this).closest('.anchor_links__group')
    $parent
      .find('.tab-item-title')
      .removeClass('active')
      .siblings('.tab-item-content')
      .slideUp()
    $(this).addClass('active').siblings('.tab-item-content').slideDown()
  }
})
