import $ from 'jquery'

$(document).ready(function () {
  dhsv_vc_magazin_filters_slider()
})

function dhsv_vc_magazin_filters_slider() {
  $('.dhsv_vc_magazin_filters_slider').each(function () {
    let $filterList = $(this).find('.magazin_tabs .controls li')
    let avg = 0
    if ($filterList.length % 2 == 0) {
      // even-length array (two middle elements)
      avg = Math.floor(0 + $filterList.length / 2)
      let second_middle = Math.floor(0 + $filterList.length / 2 - 1)
      $filterList.each(function (i) {
        if (i == avg || i == second_middle) {
          $(this).addClass('middle')
        } else if (i == avg + 1 || i == second_middle - 1) {
          $(this).addClass('inner')
        } else if (i == avg + 2 || i == second_middle - 2) {
          $(this).addClass('outer')
        } else if (i == avg + 3 || i == second_middle - 3) {
          $(this).addClass('most_outer')
        }
      })
    } else {
      avg = Math.floor(0 + $filterList.length / 2)
      $filterList.each(function (i) {
        if (i == avg) {
          $(this).addClass('middle')
        } else if (i == avg + 1 || i == avg - 1) {
          $(this).addClass('inner')
        } else if (i == avg + 2 || i == avg - 2) {
          $(this).addClass('outer')
        } else if (i == avg + 3 || i == avg - 3) {
          $(this).addClass('most_outer')
        }
      })
    }
  })

  $(document).on('click', '.magazin_tabs .controls li a', function (e) {
    e.preventDefault()
    if ($(this).hasClass('active')) {
      $(this).removeClass('active')
    } else {
      $(this).closest('.controls').find('a').removeClass('active')
      $(this).addClass('active')
    }

    /*global myajax*/

    let pageId = $(this).closest('.magazin_tabs').attr('id')
    let $container = $(this).closest('.magazin_tabs')
    let termId = $(this).closest('.magazin_tabs').data('main')
    let data = {
      action: 'magazin',
      pageId: pageId,
      termId: termId,
      category: $(this).hasClass('active') ? $(this).attr('id') : 'all',
    }
    $.ajax({
      type: 'POST',
      data: data,
      dataType: 'json',
      url: myajax.url,
    }).done(function (res) {
      $container
        .find('.magazin_slider')
        .slick('unslick')
        .html(res.html)
        .slick({
          arrows: true,
          dots: true,
          infinite: false,
          rows: 0,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: false,
          prevArrow: $container.find('.magazin_prev'),
          nextArrow: $container.find('.magazin_next'),
          slide: '.magazin-item',
          appendDots: $container.find('.dots'),
          adaptiveHeight: true,
        })
    })
  })

  //Tab Slider
  let initTabSlider = function () {
    $('.magazin_slider').each(function () {
      $(this).slick({
        arrows: true,
        dots: true,
        infinite: false,
        rows: 0,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        prevArrow: $(this).find('.magazin_prev'),
        nextArrow: $(this).find('.magazin_next'),
        slide: '.magazin-item',
        appendDots: $(this).find('.dots'),
        adaptiveHeight: true,
      })
    })
  }

  //Tab slider
  initTabSlider()
}
